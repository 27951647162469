<template>
  <hotel-profile-component />
</template>

<script>
import HotelProfileComponent from "@/components/hotels/HotelProfileComponent";

export default {
  name: "HotelProfile",
  title: "Perfil del Hotel | Baja California Health Tourism",
  components: { HotelProfileComponent },
};
</script>

<style scoped></style>
